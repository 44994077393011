<template>

  <!--  style="padding-bottom: 50px; padding-top: 50px"-->
  <v-card
    class="overflow-y-auto my-auto align-center"
    :loading="snackbar.loader"
    :rounded="false"
  >
    <v-app-bar
      :fixed="true"
      elevate-on-scroll
      hide-on-scroll
      :style="
      showbar ?'z-index: 100;top: 0; transition-delay: .3s':
      'z-index: 100;top: -100px; transition-delay: .3s'
"
      scroll-target="#scrolling-techniques-7"
    >
      <v-app-bar-nav-icon class="px-3"
                          @click="$router.back()"
      >
        <v-icon color="white">{{ icons.mdiArrowLeft }}</v-icon>
      </v-app-bar-nav-icon>
      <v-toolbar-title class="white--text">
        <!--          <span color="white">{{ chapter.comicbook_alt }}</span>--> #{{
          chapter.number
        }}

      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn
        color="white"
        icon class="mx-3"
        :to="'/catalog'"
      >
        <v-icon>{{ icons.mdiBookOpenPageVariantOutline }}</v-icon>
      </v-btn>
      <v-btn
        color="white"
        icon
        @click="dialog.reading_direction = true"
      >
        <v-icon>{{ icons.mdiSettingsHelper }}</v-icon>
      </v-btn>
      <v-btn
        color="white"
        icon class="mx-3"
        :to="'/comicbook/'+chapter.comicbook_seo+'/'+chapter.comicbook_id"
      >
        <v-icon>{{ icons.mdiMore }}</v-icon>
      </v-btn>

    </v-app-bar>

    <v-sheet
      id="scrolling-techniques-7"
      class="overflow-y-auto my-auto align-center"
      @click="toggleNav"
    >

      <!--      :reverse="chapter.readingdirection == 0? true: false"-->
      <v-responsive>
        <p
          v-if="navigation === 'wt'"
          class="text-center pt-15 pb-15  align-center"
        >
          <v-img
            v-for="(item, $index) in scanpages"
            :key="'wt-'+item.id"
            max-width="800"
            class="ma-auto"
            :src="item.src"
            :lazy-src="require('@/assets/images/holder.jpg')"
          ></v-img>

          <ScanEnd :chapter="chapter" :user="user"
                   @loadNextChapter="()=>loadChapter(chapter.nextchapter)"
                   @loadPrevChapter="()=>loadChapter(chapter.prevchapter)"
                   @initlogin="()=>{dialog.authentication = true}"
                   @rate="updateRate"
          ></ScanEnd>

        </p>
        <v-carousel
          v-else
          v-model="pageindex"
          :continuous="false"
          hide-delimiters
          :show-arrows="showarrow"
          height="auto"
          :vertical="navigation"
          class="my-auto ma-auto"
          :touchless="snackbar.touchless"
          @change="nextpage"
        >
<!--          <template v-slot:prev="{ props }">
            <v-card height="100%" width="50"
              variant="elevated"
              color="success"
              @click="props.onClick"
            >
              <v-card-title>fsfdsdfds</v-card-title>
              <v-icon>{{icons.mdiNavigationOutline}}</v-icon>
            </v-card>
          </template>-->
<!--          <template v-slot:prev="{ props }">
          <v-btn
            variant="elevated"
            color="success"
            @click="props.onClick"
          >Previous slide</v-btn>
        </template>
          <template v-slot:next="{ props }">
            <v-btn
              variant="elevated"
              color="info"
              @click="props.onClick"
            >Next slide</v-btn>
          </template>-->
          <v-carousel-item v-if="chapter.readingdirection == 0 && chapter.id"
                           key="end-rl"
                           class="text-center  align-center"
                           eager
          >
            <ScanEnd :chapter="chapter" :user="user"
                     @loadNextChapter="()=>loadChapter(chapter.nextchapter)"
                     @loadPrevChapter="()=>loadChapter(chapter.prevchapter)"
                     @initlogin="()=>{dialog.authentication = true}"
                     @rate="updateRate"
            ></ScanEnd>
          </v-carousel-item>

          <v-carousel-item
            v-for="(item, $index) in scanpages"
            :key="item.id"
            class="text-center  align-center"
            eager
          >
            <!--            @click="nextpage($index+1); pageindex++"-->
            <v-img
              max-width="800"
              class="my-auto ma-auto"
              :src="item.src"
              :lazy-src="require('@/assets/images/holder.jpg')"
            >
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
            <!--            <img @click="nextpage($index+1); pageindex++" style="max-width: 100%" :src="item.src">-->
          </v-carousel-item>

          <v-carousel-item v-if="chapter.readingdirection != 0 && chapter.id "
                           key="end-rl"
                           class="text-center  align-center"
                           eager
          >
            <ScanEnd :chapter="chapter" :user="user"
                     @loadNextChapter="()=>loadChapter(chapter.nextchapter)"
                     @loadPrevChapter="()=>loadChapter(chapter.prevchapter)"
                     @initlogin="()=>{dialog.authentication = true}"
                     @rate="updateRate"
            ></ScanEnd>
          </v-carousel-item>
        </v-carousel>
      </v-responsive>
    </v-sheet>

    <v-bottom-navigation
      id="bottom-navigation"
      :shift="showbar"
      :absolute="!showbar"
      scroll-target="#hide-on-scroll-example"
      class="position-absolute position-bottom"
      mode="shift"
      hide-on-scroll
      :style="
      showbar ?' bottom: 0; transition-delay: .3s':
      ' bottom: -100px; transition-delay: .3s'
"
    >
      <!--      <v-badge
              v-if="chapter.nb_comment > 0"
              color="primary"
              :content="chapter.nb_comment"
            >-->
      <v-btn
        color="deep-purple"
        class="text-none"
        @click="dialog.comment = true"
      >
        <!--        <v-badge :content="chapter.nb_comment" color="primary">-->
        <span class="hidden-sm-only hidden-xs-only">Commentaires</span>

        <v-icon>{{ icons.mdiComment }}</v-icon>

      </v-btn>
      <!--      </v-badge>-->
      <!--      <v-slider v-if="chapter.readingdirection == 0"
                      v-model="currentpage"
                      style="height: 100%"
                      dense
                      :label="(scanpages.length+1 - currentpage)+' / '+scanpages.length"
                      :max="scanpages.length+1"
                      :min="1"
                      step="1"
                      thumb-label
                      ticks
                      @change="nextpageslide"
            ></v-slider>-->
      <v-slider v-model="currentpage"
                style="height: 100%"
                dense
                :label="(chapter.readingdirection == 0 ? (scanpages.length+1 - currentpage)+' / '+scanpages.length : currentpage+' / '+scanpages.length )"
                :max="scanpages.length+1"
                :min="1"
                step="1"
                thumb-label
                ticks
                @change="nextpageslide"
      ></v-slider>
    </v-bottom-navigation>

    <v-dialog
      v-model="dialog.reading_direction"
      max-width="500"
    >
      <v-card>
        <v-card-title>

          <v-icon>{{ icons.mdiSettingsHelper }}</v-icon>
          <span class=" ">Paramettres</span>

          <v-spacer></v-spacer>
          <v-btn
            text
            icon
            @click="dialog.reading_direction = false"
          >
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <h3>Sens de lecture</h3>
          <v-radio-group v-if="chapter.readingdirection != 2"
                         v-model="navigation">
            <v-radio
              :label="`Horizontal`"
              :value="false"
            ></v-radio>
            <v-radio
              :label="`Verical`"
              value="wt"
            ></v-radio>
          </v-radio-group>
          <p v-else>
            Les webtoon se lisent de manier verticale uniquement.
          </p>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialog.reading_direction_alert"
      max-width="500"
    >
      <v-card>
        <v-card-title><h3 class="text-center">Sens de lecture</h3>
          <v-spacer></v-spacer>
          <v-btn
            text
            icon
            @click="dialog.reading_direction_alert = false"
          >
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
        </v-card-title>
      </v-card>
      <v-img v-if="chapter.readingdirection == 1"
             class="ma-auto"
             :src="require('@/assets/images/pages/reading-lr.png')"
      ></v-img>
      <v-img v-else-if="chapter.readingdirection == 0"
             class="ma-auto"
             :src="require('@/assets/images/pages/reading-rl.png')"
      ></v-img>

    </v-dialog>

    <v-dialog
      v-model="dialog.startreading"
      max-width="500"
    >
      <v-card>
        <v-card-title>
          Info
          <v-spacer></v-spacer>
          <v-btn
            text
            icon
            @click="dialog.startreading = false"
          >
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          Vous pouvez acheter ce chapitre à l'aide de vos credits bonus.<br>
        </v-card-text>
        <v-card-text>
          <div class="d-flex align-center justify-space-between flex-wrap">
            <v-checkbox
              v-model="user.config.buyauto"
              :label="'Ne plus demander et acheter automatique après les pages d\'extrait '"
              hide-details
              class="me-3 mt-1"
            >
            </v-checkbox>
          </div>
          <!-- forgot link -->
          <v-divider></v-divider>
          <p
            v-if="user.config.buyauto"
            class="mt-1"
          >
            <i>les chapitres seront automatiquement achetés avec vos credits bonus. Vous pouvez
              changer cette option dans les parametres de votre compte. <a href="/settings">En
                savoir plus ...</a> </i>
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :loading="snackbar.loadconfig"
            color="primary"
            @click="configure()"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialog.subscription"
      max-width="500"
    >
      <v-card>
        <v-card-title>
          {{ chapter.comicbook_alt }} #{{ chapter.number }} - {{ chapter['product'].price }} Credits
          <v-spacer></v-spacer>
          <v-btn
            text
            icon
            @click="resetswipe()"
          >
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
        </v-card-title>

        <template v-if="user_id">
          <v-card-text class="text-center">
            Vous avez atteind le nombre de page limite. Le chapitre coute
            <b>{{ chapter.product.price }}
              Credits</b>.

            <div v-if="subscription_id">
              Vous avez encore {{ subscription.credit }} credits bonus de votre abonnement du
              {{ subscription.date_from }} et qui se termine le {{ subscription.date_to }}<br>
            </div>
            <div v-else>
              Vous pouvez l'acheter directement ou alors
              <router-link to="/subscription/1">
                souscrire a un abonnement
              </router-link>
              pour avoir des credits bonus.
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn
              v-if="subscription_id"
              block
              class="mt-3"
              color="primary"
              :loading="dialog.purchase"
              @click="getChapter('bonus')"
            >
              Acheter Avec mon credit bonus
            </v-btn>

            <v-btn
              block
              class="mt-3"
              color="primary"
              :loading="dialog.purchase"
              @click="getChapter()"
            >
              Acheter Avec mon credit principal
            </v-btn>
          </v-card-actions>
          <v-card-actions>
            <v-btn
              block
              class="mt-3"
              text
              @click="dialog.subscription = false"
            >
              Annuler
            </v-btn>
          </v-card-actions>
        </template>

        <v-card-text
          v-else
          class="text-center"
        >
          <v-alert color="warning">
            Vous avez atteind le nombre de page limite vous devez vous
            connecter pour lire la suite.
          </v-alert>
          <login-component @logged="logged"></login-component>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialog.authentication"
      max-width="600"
      fullscreen
    >
      <v-card-title>
        <v-spacer></v-spacer>
        <v-btn color="primary" rounded @click="dialog.authentication = false">
          <v-icon>{{ icons.mdiClose }}</v-icon>
          Fermer et continuer la lecture
        </v-btn>
      </v-card-title>
      <login-component
        :newaccount="false"
        @logged="logged"
        @closedialog="()=> {dialog.authentication = false}"
      ></login-component>
    </v-dialog>

    <v-dialog
      v-model="dialog.alert"
      width="300"
    >
      <v-card color="error">
        <v-card-title class="text-center">
          {{ dialog.alert_message }}
        </v-card-title>
        <v-card-actions
          v-if="dialog.code == -3"
          class="text-center"
        >
          <v-btn
            block
            small
            to="/subscription"
          >
            Faire une nouvelle souscription
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar.snack"
      :color="snackbar.color"
    >
      {{ snackbar.text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="snackbar.snack = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-bottom-sheet v-if="chapter.post_id"
                    v-model="dialog.comment"
                    max-width="800"
    >
      <v-card class="overflow-auto " max-height="550">
        <v-card-title class="comment-header">
          Commentaires ({{ chapter.nb_comments }})
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="dialog.comment = false"
          >
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
        </v-card-title>
        <comment-component
          :post_id="parseInt(chapter.post_id)"
          :quick_comment="false"
          :limit="12"
          :user="user"
          @initlogin="()=>{dialog.authentication = true}"
        ></comment-component>
      </v-card>
    </v-bottom-sheet>

  </v-card>

</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiChevronRight,
  mdiChevronLeft,
  mdiArrowAll,
  mdiNavigationOutline,
  mdiComment,
  mdiInformationOutline,
  mdiEyeOutline,
  mdiClose,
  mdiHomeOutline,
  mdiEyeOffOutline,
  mdiShareVariantOutline,
  mdiBookSettings,
  mdiSettingsHelper,
  mdiArrowLeft,
  mdiBookOpenPageVariantOutline,
  mdiMore
} from '@mdi/js'
import {computed, ref} from '@vue/composition-api'
import {Drequest} from '@/plugins/Drequest'
import {getVuetify} from '@/plugins/utils'
import {useRouter} from '@/utils'
import LoginComponent from '@/components/LoginComponent'
import AppBarUserMenu from '@/layouts/components/AppBarUserMenu'
import CommentComponent from '@/components/CommentComponent'
import ToggleFavoriteComponent from '@/components/ToggleFavoriteComponent'
import goTo from 'vuetify/lib/services/goto'
import ShareComponent from '@/components/ShareComponent'
import ScanEnd from "@/components/scan/ScanEnd.vue";
import CommentFormComponent from "@/components/CommentFormComponent.vue";
import store from "@/store";

export default {
  components: {
    CommentFormComponent,
    ScanEnd,
    ShareComponent,
    ToggleFavoriteComponent,
    CommentComponent,
    AppBarUserMenu,
    LoginComponent,
  },

// child
  metaInfo() {
    return {
      title: this.chapter.id ? `${this.chapter.comicbook_name} #${this.chapter.number}` : "Scan",
      meta: [

        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        {
          vmid: 'og:url',
          property: 'og:url',
          content: Drequest.__env+`scan/${this.chapter.comicbook_seo}/${this.chapter.id}`
        },
        {
          vmid: 'og:description',
          property: 'og:description',
          content: `/ Chapitre ${this.chapter.number} - ${this.chapter.title}`
        },
        {
          vmid: 'og:image',
          property: 'og:image',
          content: `${this.chapter.cover_src_sm}`
        },

      ]
    }
  },
  props: {
    height: Number,
  },
  methods: {
    updateRate(rate) {
      this.chapter.user_rate = rate
    },
    toggleNav() {
      if (!this.snackbar.endchapter)
        this.showbar = !this.showbar
    }
  },
  setup() {
    const $vuetify = getVuetify()
    const showbar = ref(true)
    let lastScrollTop = 0
    let pageheight = 0
    let nextiterator = 1
    // element should be replaced with the actual target element on which you have applied scroll, use window in case of no target element.
    window.addEventListener('scroll', () => { // or window.addEventListener("scroll"....

      if (navigation.value !== 'wt')
        return 0;

      const stw = window.scrollY // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
      const stb = document.documentElement.scrollTop // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
      const st = window.scrollY || document.documentElement.scrollTop // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
      if (st > lastScrollTop) {
        // downscroll code
        showbar.value = false
      } else {
        // upscroll code
        showbar.value = true
      }
      lastScrollTop = st <= 0 ? 0 : st // For Mobile or negative scrolling

      pageheight = document.getElementById('scrolling-techniques-7')
        .getElementsByClassName('v-image')[0].clientHeight - 50

      nextiterator = parseInt(stw / pageheight) + 1
      if (pageindex.value > nextiterator) {
        currentpage.value = nextiterator
        return 0
      }

      console.log(currentpage.value, nextiterator, scanpages.value.length)
      if (currentpage.value !== nextiterator && nextiterator < scanpages.value.length) {
        nextpage((nextiterator - 1))
      }

    }, false)

    const user_id = ref(localStorage.getItem('user_id'))
    const user = ref(Drequest.getUser())
    const subscription_id = ref(localStorage.getItem('subscription_id'))

    if (user_id.value) {
      Drequest.updateUserSession(user.value, () => {
        user.value = Drequest.getUser()
        subscription_id.value = JSON.parse(localStorage.getItem('subscription_id'))

        console.log(user.value, subscription_id.value)
      })
    }

    const {router} = useRouter()
    const route = router.currentRoute
    const {id} = route.params
    const numberchapter = route.params.number
    const comicbookname = route.params.comicbook
    const chapterpage = route.params.page
    console.log(id, numberchapter, comicbookname)
    const chapter = ref({product: {}})
    const scanpages = ref([])
    const currentpage = ref(1)
    const pageindex = ref(0)
    const subscription = ref({})
    const dialog = ref({
      comment: false,
      reading_direction: false,
      reading_direction_alert: false,
      subscription: false,
      purchase: false,
      alert: false,
      alert_message: false,
      login: false,
      startreading: false,
      authentication: false,
      sharebox: false,
    })
    const snackbar = ref({
      color: 'primary',
      text: '',
      snack: false,
      endchapter: false,
      loadconfig: false,
      loader: true,
      touchless: false,
    })
    const navigation = ref(false)

    const loadPages = id => {
      Drequest.api(`chapter.scanpages?dfilters=on&next=1&per_page=all&dsort=position asc&bought=1&chapter_id:eq=${id}`)
        .get(response => {
          console.log(response)
          scanpages.value = response.scanpages
          snackbar.value.loader = false

          // currentpage.value = 1
        })
    }

    const loadChapter = id => {
      currentpage.value = (1)
      pageindex.value = (0)
      snackbar.value.loader = true

      if (user_id.value) {
        if (user.subscription) {
          Drequest.getSubscriptionDetail(subscription_id.value)
            .get(response => {
              subscription.value = response.subscription
            })
        }
        let url = `chapter.detail?reader=1&id=${id}`
        if (numberchapter) {
          url = `chapter.detail?reader=1&number=${numberchapter}&comicbookname=${comicbookname}`
        }
        Drequest.api(`${url}&user_id=${user_id.value}`)
          .get(response => {
            console.log(response)
            snackbar.value.loader = false
            dialog.value.reading_direction_alert = true
            chapter.value = response.chapter
            /*store.commit('updateMetatag', {
              image: chapter.value.cover_src_sm,
              title: `/ ${chapter.value.comicbook_name} - Chapitre ${chapter.value.number}`,
              content: `/ Chapitre ${chapter.value.number} - ${chapter.value.title}`,
              titleTemplate: null
            });*/
            if (chapter.value.comicbook_readingdirection == 2) navigation.value = 'wt'

            else if (chapter.value.readingdirection == 0) {
              currentpage.value = (response.scanpages.length)
              pageindex.value = (response.scanpages.length)
            }

            scanpages.value = response.scanpages
            if (chapterpage) {
              currentpage.value = chapterpage
              pageindex.value = chapterpage - 1
            }

            //
            if (subscription_id.value && !user.value.config.buyauto && !chapter.value.bought) {
              dialog.value.startreading = true
            }

            Drequest.analytic('chapter', chapter.value.id, user_id.value)
            //Drequest.analytic('scanpage', scanpages.value[0].id, user_id.value)
          })
      } else {
        let url = `chapter.detail?reader=1&id=${id}`
        if (numberchapter) {
          url = `chapter.detail?reader=1&number=${numberchapter}&comicbookname=${comicbookname}`
        }
        Drequest.api(url)
          .get(response => {
            console.log(response)
            snackbar.value.loader = false
            chapter.value = response.chapter

            if (chapter.value.comicbook_readingdirection == 2) navigation.value = 'wt'
            else if (chapter.value.readingdirection == 0) {
              currentpage.value = (response.scanpages.length)
              pageindex.value = (response.scanpages.length)
            }
            // if (numberchapter) {

            scanpages.value = response.scanpages
            if (chapterpage) {
              currentpage.value = chapterpage
              pageindex.value = chapterpage - 1
            }
            Drequest.analytic('chapter', chapter.value.id, user_id.value)
            //Drequest.analytic('scanpage', scanpages.value[0].id, user_id.value)

            // loadPages(chapter.value.id)

            // }
          })
      }
    }
    loadChapter(id)

    const logged = (vuser, message) => {
      user_id.value = vuser.id
      user.value = vuser
      subscription_id.value = vuser.subscription
      dialog.value.subscription = false
      dialog.value.authentication = false
      snackbar.value.snack = true
      snackbar.value.color = 'success'
      snackbar.value.text = message
      nextpage(currentpage.value)
    }

    const callbackPurchase = response => {
      dialog.value.purchase = false
      console.log(response)
      if (response.success) {
        chapter.value.bought = true
        snackbar.value.touchless = false
        dialog.value.subscription = false
        currentpage.value++
        pageindex.value = currentpage.value - 1
        snackbar.value.snack = true
        snackbar.value.text = response.detail
        loadPages(chapter.value.id)
      } else {
        snackbar.value.touchless = true
        dialog.value.alert = true
        dialog.value.code = response.code
        dialog.value.alert_message = response.detail
        if (dialog.value.code == -3) {
          // not authorised to continue the reading
          snackbar.value.touchless = true
          dialog.value.subscription = true
          currentpage.value = chapter.value.free_pages
          pageindex.value = chapter.value.free_pages - 1
        }
      }
    }
    const getChapter = method => {
      dialog.value.purchase = true
      if (method === 'bonus') {
        Drequest.orderWithSubscription(chapter.value)
          .raw(callbackPurchase)
      } else {
        if (!confirm('Confirmer l\'achat du chapitre avec votre credit principal?')) {
          dialog.value.purchase = false

          return 0
        }

        Drequest.orderWithCredit(chapter.value)
          .raw(callbackPurchase)
      }
    }

    const nextpage = iterator => {
      console.log(iterator)
      if (iterator >= chapter.value.free_pages && chapter.value.product.price > 0 && !chapter.value.bought) {
        if (user_id.value) {
          if (user.value.config.buyauto) {
            getChapter('bonus')
          } else {
            // not authorised to continue the reading
            snackbar.value.touchless = true
            dialog.value.subscription = true
            currentpage.value = chapter.value.free_pages
            pageindex.value = chapter.value.free_pages - 1

            return
          }
        } else {
          // not authorised to continue the reading
          snackbar.value.touchless = true
          dialog.value.subscription = true
          currentpage.value = chapter.value.free_pages
          pageindex.value = chapter.value.free_pages - 1

          return
        }
      }

      if (chapter.value.readingdirection == 0) {
        if (iterator <= 0 && !snackbar.value.endchapter) {
          snackbar.value.endchapter = true
          showbar.value = true
        } else {
          snackbar.value.endchapter = false
        }
      } else if ((iterator >= scanpages.value.length && !snackbar.value.endchapter)) {
        snackbar.value.endchapter = true
        showbar.value = true
      } else {
        snackbar.value.endchapter = false
      }

      //Drequest.analytic('scanpage', scanpages.value[iterator].id, user_id.value)
      currentpage.value = iterator + 1
    }

    const nextpageslide = () => {

      pageindex.value = currentpage.value
      if (navigation.value === 'wt') {
        console.log('scrollup')
        if (!pageheight) {
          pageheight = document.getElementById('scrolling-techniques-7')
            .getElementsByClassName('v-image')[0].clientHeight - 50
        }
        goTo(pageheight * (pageindex.value))
      }
      nextpage(currentpage.value - 1)
    }
    const configure = () => {
      // configuration update user
      if (!user.value.config.buyauto) {
        dialog.value.startreading = false

        return 0
      }

      snackbar.value.loadconfig = true
      Drequest.api(`update.user?id=${user_id.value}`)
        .data({user: {config: user.value.config}})
        .raw(response => {
          snackbar.value.loadconfig = false
          dialog.value.startreading = false
          console.log(response)
          Drequest.setUser(user.value)
        })
    }

    const showarrow = computed(() => {
      if ($vuetify.breakpoint.sm) {
        return false
      }
      if ($vuetify.breakpoint.xs) {
        return false
      }

      return true
    })

    const resetswipe = () => {
      dialog.value.subscription = false
      snackbar.value.touchless = false
      currentpage.value = chapter.value.free_pages - 1
      pageindex.value = chapter.value.free_pages - 2
    }

    return {
      logged,
      nextpage,
      nextpageslide,
      getChapter,
      loadChapter,
      configure,
      resetswipe,

      user,
      showbar,
      currentpage,
      pageindex,
      subscription,
      scanpages,
      chapter,
      showarrow,
      navigation,
      dialog,
      snackbar,

      user_id,
      subscription_id,

      icons: {
        mdiClose,
        mdiChevronRight,
        mdiChevronLeft,
        mdiArrowAll,
        mdiComment,
        mdiNavigationOutline,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiInformationOutline,
        mdiHomeOutline,
        mdiShareVariantOutline,
        mdiBookSettings,
        mdiMore,
        mdiArrowLeft,
        mdiSettingsHelper,
        mdiBookOpenPageVariantOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';

.v-messages.theme--light {
  display: none;
}

.v-input__slot, .v-input__control {
  height: 100%;
}

.endchapter {
  position: fixed;
  width: 100%;
  bottom: 50px;
}
</style>
