<template>
  <div>

    <v-card-title class="justify-end">
      <!--      <h3>{{ chapter.comicbook_name }}</h3> :-->
      <h3>A suivre</h3>
    </v-card-title>
    <v-card-text>
      <v-divider></v-divider>
      Qu'avez vous pensé de ce chapitre?
      <v-rating
        v-model="rating.note"
        hover
        :length="5"
        :size="60"
        :model-value="3"
        active-color="primary"
        @click="evaluate"
      />
      <v-btn :loading="loader.cancel"
             @click="cancel" small v-if="rating.note > 0">Annuler
      </v-btn>
    </v-card-text>
    <v-card-text>
      Laissez votre avis en commentaires
      <CommentFormComponent :answerto="{}"
                            :post_id="parseInt(chapter.post_id)" :user="user"
                            @sent="newcomment"
                            @initlogin="()=>$emit('initlogin')"
      ></CommentFormComponent>
    </v-card-text>
    <v-card-actions
      class="justify-center"
    >
      <v-fab-transition>
        <toggle-favorite-component
          :fab="true"
          :chapter="chapter"
          :nbkola="parseInt(chapter.nbkola)"
          @updateNotify="(response)=>{chapter.isfavorite = response.isfavorite; chapter.nbkola = response.nbkola}"
        ></toggle-favorite-component>
      </v-fab-transition>
      <v-fab-transition>
        <share-button-component
          :url="'/scan/'+chapter.comicbook_seo+'/'+chapter.id"
          :title="chapter.title"
          :message="'Nouveau chapitre'"
        ></share-button-component>
<!--        <v-btn small link color="warning" @click="dialog.sharebox = true" class="ml-3" rounded>
          <v-icon>{{ icons.mdiShareVariantOutline }}</v-icon>
          <span class="ml-3 hidden-xs-only hidden-sm-only">Partager</span>
        </v-btn>-->
      </v-fab-transition>
    </v-card-actions>

    <v-card-actions
      class="end chapter"
    >
      <v-fab-transition>
        <v-btn
          v-if="chapter.prevchapter"
          :to="'/scan/'+chapter.comicbook_alt+'/'+chapter.prevchapter"
          rounded
          left small
          class="ma-3 "
          @click="$emit('loadPrevChapter')"
        >
          <v-icon>{{ icons.mdiChevronLeft }}</v-icon>
          <span class=" ">Chapitre precedent</span>
        </v-btn>
      </v-fab-transition>
      <v-spacer></v-spacer>

      <v-fab-transition>
        <v-btn
          v-if="chapter.nextchapter"
          :to="'/scan/'+chapter.comicbook_alt+'/'+chapter.nextchapter"
          rounded small
          color="primary"
          class="ma-3  "
          @click="$emit('loadNextChapter')"
        >
          <span class=" ">Chapitre suivant</span>
          <v-icon>{{ icons.mdiChevronRight }}</v-icon>
        </v-btn>
      </v-fab-transition>
    </v-card-actions>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiFacebook,
  mdiTwitter,
  mdiGithub,
  mdiGoogle,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiClose,
  mdiChevronRight,
  mdiChevronLeft,
  mdiArrowAll,
  mdiComment,
  mdiNavigationOutline,
  mdiInformationOutline,
  mdiHomeOutline,
  mdiShareVariantOutline,
  mdiBookSettings,
} from '@mdi/js'
import {computed, ref, watch} from '@vue/composition-api'
import {Drequest} from '@/plugins/Drequest'
import {getVuetify} from '@/plugins/utils'
import ToggleFavoriteComponent from "@/components/ToggleFavoriteComponent.vue";
import ShareComponent from "@/components/ShareComponent.vue";
import CommentFormComponent from "@/components/CommentFormComponent.vue";
import SponsorPrompt from "@/layouts/components/SponsorPrompt.vue";
import ShareButtonComponent from "@/components/ShareButtonComponent.vue";

export default {
  components: {
    ShareButtonComponent,
    SponsorPrompt, CommentFormComponent, ShareComponent, ToggleFavoriteComponent},
  props: {
    chapter: {
      type: Object,
      default: () => {
      },
    },
    user: {
      type: Object,
      default: () => {
      },
    },
  },
  mounted() {
    this.rating.note = this.chapter.user_rate
  },
  watch: {
    rating: {
      handler(val){
        if(this.chapter.user_rate != this.rating.note) {
          if (!this.user.id) {
            console.log( this.chapter.user_rate, this.rating.note)
            this.$emit('initlogin')
            //this.$store.commit('updateAuthentication', true)
            return null;
          }

          this.evaluate(val.note, () => {
            this.$emit('rate', val.note)
          })
        }
      },
      deep : true
    },
    chapter: {
      handler(val){
        console.log(val)
        this.rating.note = val.user_rate
      },
      deep : true
    },
  },
  setup(props) {
    const rating = ref({note: 0})
    const loader = ref({cancel: false})
    const dialog = ref({
      sharebox: false
    })
    const newcomment = response => {
      console.log(response)
      alert('Votre commentaire a bien ete publie. Vous pouvez le voir en cliquant sur l icone commentaire sur la barre du bas')
    }

    const cancel = () => {
      loader.value.cancel = true
      Drequest.api('chapter.cancelnote').param(
        {'user_id': props.user.id, 'chapter_id': props.chapter.id}
      ).get(response => {
        loader.value.cancel = false
        console.log(response)
      })
      rating.value.note = 0
    }
    const evaluate = (note, callback) => {
      loader.value.cancel = true
      Drequest.api('chapter.note')
        .param(
          {'user_id': props.user.id, 'chapter_id': props.chapter.id}
        )
        .toFormdata({'note': note})
        .post(response => {
          console.log(response)
          loader.value.cancel = false
          callback()
        })
    }
    return {
      evaluate,
      cancel,
      newcomment,

      dialog,
      loader,
      rating,
      icons: {
        mdiClose,
        mdiChevronRight,
        mdiChevronLeft,
        mdiArrowAll,
        mdiComment,
        mdiNavigationOutline,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiInformationOutline,
        mdiHomeOutline,
        mdiShareVariantOutline,
        mdiBookSettings,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';

</style>
